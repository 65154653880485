@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
body {
  background: #0a466e;
}

.confirm-alert-overlay {
  z-index: 2000 !important;
  background: #0000 !important;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  -webkit-animation: react-confirm-alert-fadeIn 0.2s forwards !important;
          animation: react-confirm-alert-fadeIn 0.2s forwards !important;
}

